@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --color-button-text: #fff;
  --color-primary-100: #e6eef8;
  --color-primary-200: #b2bdcd;
  --color-primary-300: #5d7290;
  --color-primary-600: #323d4d;
  --color-primary-700: #242c37;
  --color-primary-800: #151a21;
  --color-primary-900: #0b0e11;
  --color-secondary-washed-out: #37649e;
  --color-secondary: #1d2a36;
  --color-secondary-dark: #3158e6;
  --color-accent-glow: rgba(253, 77, 77, 0.3);
  --color-accent: #ff7700;
  --color-accent-washed: #ff902ead;
  --color-bg-dark: #0f1527;
  --color-bg-light: #1c3351;
  --color-white: #ffffff;
  --theme-bg-color: #10121b66;
  --color-bg-dark-shade: #0f1527be;
  --color-like: #2078F4;
}

body {
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
}

.operateCard {
  box-shadow: 0 0 35px -2px rgb(0 0 0 / 20%);
  transition: all ease-in-out 500ms;
}

::selection {
  background-color: var(--color-accent-washed);
  color: rgb(0, 0, 0);
}

.outputFont {
  font-family: "Inconsolata", monospace;
}

.editorFont {
  font-family: "Inconsolata", monospace !important;
}

.customFont {
  font-family: "Poppins", sans-serif;
}

*::-webkit-scrollbar-track {
  background-color: var(--color-bg-dark);
}

*::-webkit-scrollbar {
  width: 3px;
  background-color: rgb(255, 94, 0);
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(255, 94, 0);
}

*::-webkit-scrollbar-track:horizontal {
  background-color: var(--color-bg-dark) !important;
}

*::-webkit-scrollbar:horizontal {
  height: 3px !important;
  background-color: rgb(255, 94, 0) !important;
}

*::-webkit-scrollbar-thumb:horizontal {
  border-radius: 5px !important;
  background-color: rgb(255, 94, 0) !important;
}

.customScroll::-webkit-scrollbar-track,
#ACEEditor::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.customScroll::-webkit-scrollbar,
#ACEEditor::-webkit-scrollbar {
  width: 2px !important;
  background-color: transparent !important;
  position: absolute !important;
}

.customScroll::-webkit-scrollbar-thumb,
#ACEEditor::-webkit-scrollbar-thumb {
  background-color: gray;
}

textarea,
input:active,
input:focus,
textarea:active,
textarea:focus {
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-width: 0;
}