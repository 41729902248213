.editorOutput:active,
.editorOutput:focus {
  box-shadow: 1px 1px 1px 1px var(--theme-bg-color);
}

.background-blob {
  /* position: relative; */
  height: 100vh;
}

.background-blob:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 58.3px;
  width: 100%;
  height: 100%;
  /* background-image: url('../utils/assets/blob.svg'); */
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

/* .background-compiler {
  position: relative;
} */

.background-compiler:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../utils/assets/compiler.svg'); */
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.background-blob-contests {
  position: relative;
}

.background-blob-contests:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../utils/assets/blob-contests.svg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.background-contest {
  position: relative;
}

.background-contest:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: var(--color-primary-200);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  border-radius: 5px;
}

.background-contest-details::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 58.3px;
  width: 100%;
  height: 100%;
  /* background-image: url('../utils/assets/blob-contests-details.svg'); */
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.rotateY {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.glassmorp {
  overflow: hidden;
  position: relative;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  --tw-shadow: 0 1.5px 1px 0px #ffffff88;
  --tw-shadow-colored: 1px 1px 1px 1px #ffffffa4;
}

.glassmorp-notShadow {
  background-color: var(--theme-bg-color);
  overflow: hidden;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.glassmorp-social {
  background-color: var(--color-bg-dark-shade);
  overflow: hidden;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.glassmorp-name {
  background-color: var(--color-bg-light);
  overflow: hidden;
  position: relative;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.glassmorp-block-contest {
  background: radial-gradient(
      ellipse 90% 100% at bottom left,
      rgba(255, 255, 255, 0.788),
      transparent
    ),
    radial-gradient(
      ellipse 90% 100% at bottom right,
      rgba(255, 255, 255, 0.788),
      transparent
    ),
    linear-gradient(to top, white 20%, transparent);
  background-size: 100% 100%, 100% 80%, 100% 100%;
  background-position: top left, left 100%, top left;
  background-repeat: no-repeat;
}

.glassmorp-block-contest:before {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.ioBox {
  box-shadow: inset 10px -7px 20px 0px rgb(0 0 0 / 8%);
  -webkit-box-shadow: inset 10px -7px 20px 0px rgb(0 0 0 / 8%);
}

.terminal-button {
  width: 12px;
  height: 12px;
  margin: 10px 4px 0 0;
  display: inline-block;
  border-radius: 50%;
}

.green {
  background: #3bb662;
}

.yellow {
  background: #e5c30f;
}

.red {
  background: #e75448;
}

.custome-shadow {
  --tw-shadow: 0 2px 5px 0px #030303a1;
  --tw-shadow-colored: 5px 5px 10px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.wrapper {
  background: #fff;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 7px 7px 12px rgba(0, 0, 0, 0.05);
}

.wrapper header {
  color: #051336;
  font-size: 27px;
  font-weight: 600;
  text-align: center;
}

.upForm {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 2px dashed var(--color-accent);
  width: 100%;
  margin: 0 auto;
}

.upForm p {
  font-size: 12px;
}

section .row {
  margin-bottom: 10px;
  background: var(--color-bg-dark);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

section .row i {
  color: var(--color-accent);
  font-size: 20px;
}

section .details span {
  font-size: 14px;
}

.progress-area .row .content {
  width: 100%;
  margin-left: 15px;
}

.progress-area .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-area .content .progress-bar {
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 30px;
}

.content .progress-bar .progress {
  height: 100%;
  width: 0%;
  background: #6990f2;
  border-radius: inherit;
}

.uploaded-area {
  max-height: 232px;
  overflow-y: scroll;
}

.uploaded-area.onprogress {
  max-height: 150px;
}

.uploaded-area::-webkit-scrollbar {
  width: 0px;
}

.uploaded-area .row .content {
  display: flex;
  align-items: center;
}

.uploaded-area .row .details {
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}

.uploaded-area .row .details .size {
  color: #404040;
  font-size: 10px !important;
}

.contest-card,
.contest-active,
.contest-upcoming,
.contest-archive {
  position: relative;
}

.contest-card::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--color-accent);
}

.contest-active::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: green;
}

.contest-upcoming::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--color-accent);
}

.contest-archive::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--color-primary-300);
}

.contest-name {
  position: relative;
}

.contest-name::before {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: -15px;
  height: 4px;
  width: 20%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--color-accent);
  transform-origin: left;
  transform: scaleX(0);
  transition: all 400ms ease;
}

.contest-name:hover::before {
  transform: scaleX(1);
}

.contest-without {
  position: relative;
}

.contest-without::before {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: -15px;
  height: 4px;
  width: 20%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: var(--color-accent);
}

.githubTabClone {
  position: relative;
}

.githubTabClone::after {
  content: ' ';
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 7px;
  width: 100%;
  background-color: white;
}

div[data-type='Resizer'] {
  cursor: col-resize;
  width: 5px;
  background: rgb(255, 255, 255);
  opacity: 1;
  z-index: 1;
  border: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  transition: none;
}

div[data-type='Resizer']:hover {
  background: rgb(209, 209, 209);
  width: 5px;
  transition: all 300ms ease-in-out;
  border: 0;
}

div[data-type='Resizer']::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background: var(--color-accent);
  top: 50%;
  bottom: 50%;
  margin-top: auto;
  margin-bottom: auto;
  transition-property: transform;
  transition-duration: 0.25s;
}

div[data-type='Resizer']::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 20px;
  background: var(--color-accent);
  top: 50%;
  bottom: 50%;
  margin-top: auto;
  margin-bottom: auto;
  transition-property: transform;
  transition-duration: 0.25s;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.operateCard {
  box-shadow: 0 0 35px -2px rgb(0 0 0 / 20%);
  transition: all ease-in-out 500ms;
}

.operateCard:hover {
  cursor: pointer;
}

.comment-options {
  animation: fade 200ms ease-in-out;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
